<script>
	const survivors = 4
	const minCount = 0
	const maxCount = 3

	let survivorsLives = Array.from({ length: survivors }, () => maxCount)
	let lastSurvivorStateChange = -1

	const keyMap = {
		reset: {
			keys: ['Numpad0'],
			action: (arr, _) => {
				lastSurvivorStateChange = -1

				return arr.map(i => maxCount)
			}
		},
		win: {
			keys: ['NumpadDecimal'],
			action: (arr, _) => {
				lastSurvivorStateChange = -1

				return arr.map(i => 0)
			}
		},
		hook: {
			keys: ['NumpadDivide', 'Numpad8', 'Numpad5', 'Numpad2'],
			action: (arr, key) => {
				if (arr[key] > minCount) {
					arr[key]--

					if (arr[key] > minCount) {
						lastSurvivorStateChange = key
					} else {
						lastSurvivorStateChange = -1
					}
				}

				return arr
			}
		},
		unhook: {
			keys: ['NumpadMultiply', 'Numpad9', 'Numpad6', 'Numpad3'],
			action: (arr, key) => {
				if (arr[key] < maxCount) {
					lastSurvivorStateChange = key
					arr[key]++

					if (arr[key] < maxCount) {
						lastSurvivorStateChange = key
					} else {
						lastSurvivorStateChange = -1
					}
				}

				return arr
			}
		},
	}

	function getStage(lives) {
		return lives === 2 ? 1 : lives === 1 ? 2 : lives === 0 ? 3 : 0
	}

	function getStatusImage(lives) {
		const statusList = {
			3: 'healthy',
			2: 'hooked',
			1: 'hooked',
			0: 'sacrificed',
		}

		return `${statusList[lives]}.png`
	}

	document.addEventListener('keypress', function (e) {
		// loop through keyMap keys and check if key pressed is in it without using e.keyCode because it's deprecated
		for (let key in keyMap) {
			if (keyMap[key].keys.includes(e.code)) {
				survivorsLives = keyMap[key].action(
					survivorsLives,
					keyMap[key].keys.indexOf(e.code)
				)
			}
		}
	})
</script>

<main>
	<div class="mobile-controls">
		<button on:click={() => { survivorsLives = keyMap['reset'].action(survivorsLives) }}>reset entity</button>
		<button on:click={() => { survivorsLives = keyMap['win'].action(survivorsLives) }}>sacrifice</button>
	</div>

	<div class="survivors">
		{#each survivorsLives as survivorLives, survivorKey}
			<div class="survivor" class:recent={lastSurvivorStateChange === survivorKey}>
				<div class="stage stage-{getStage(survivorLives)}">
					<img src="{getStatusImage(survivorLives)}" alt="">

					{#if survivorLives < maxCount && survivorLives > 0}
						<progress min="{minCount}" max="{maxCount - 1}" value="{survivorLives}" />
					{/if}
				</div>
				<div class="mobile-controls">
					<button on:click={() => { survivorsLives = keyMap['hook'].action(survivorsLives, survivorKey) }} class="hook" disabled={survivorLives <= minCount}>+</button>
					<button on:click={() => { survivorsLives = keyMap['unhook'].action(survivorsLives, survivorKey) }} class="unhook" disabled={survivorLives >= maxCount}>-</button>
				</div>
			</div>
		{/each}
	</div>
</main>

<style>
	:root {
		--black: rgb(10, 8, 8);
	}

	.survivors {
		text-align: -webkit-center;
	}

	.survivor {
		position: relative;
		border-radius: 0.25rem;
		width: fit-content;
	}

	.survivor:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.survivor.recent {
		box-shadow: 0 0 10px 0 red;
	}

	.stage {
		position: relative;
		display: inline-flex;
		padding: 0.5rem 0.5rem 1rem 0.5rem;
		background: var(--black);
		border-radius: 0.25rem;
		text-align: left;
	}

	.stage img {
		display: block;
		width: 10rem;
		height: auto;
	}

	.stage progress {
		border: none;
		width: auto;
		height: 0.5rem;
		position: absolute;
		left: 0.5rem;
		right: 0.5rem;
		bottom: 0.25rem;
	}

	.stage ::-webkit-progress-bar {
		background: var(--black);
	}

	.stage ::-webkit-progress-value {
		background: red;
		border: 1px solid var(--black);
	}

	.mobile-controls {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: -40%;
		bottom: 0;
		width: 35%;
	}

	.mobile-controls button {
		font-family: "Courier New", Courier, monospace;
		width: 100%;
		height: 100%;
		border: none;
		background: var(--black);
		color: white;
		font-size: 1.5rem;
		font-weight: bold;
		border-radius: 0.25rem;
		cursor: pointer;
	}

	.mobile-controls button.hook {
		background: darkred;
	}

	.mobile-controls button:not(:last-child) {
		margin-bottom: 0.25em;
	}

	.mobile-controls button[disabled] {
		opacity: 0.35;
		pointer-events: none;
		background: var(--black);
	}

	main > .mobile-controls {
		position: relative;
		flex-direction: row;
		font-size: 0.75rem;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: 2rem;
	}

	main > .mobile-controls button {
		padding: 1ch 0.5ch;
		margin: 0 1ch;
		font-size: 1rem;
		font-family: sans-serif;
	}

	/* hide .mobile-controls on desktop */
	@media (min-width: 640px) {
		.mobile-controls {
			display: none;
		}
	}
</style>